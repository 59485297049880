import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import FormData from 'form-data'
import Trumbowyg from 'vue-trumbowyg'
import '../../../../node_modules/trumbowyg/dist/plugins/table/trumbowyg.table'
import forEach from 'lodash/forEach'
import upperCase from 'lodash/upperCase'
import startCase from 'lodash/startCase'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Services
import SuppliersService from '@/services/Suppliers'

@Component({
  components: { Trumbowyg }
})
export default class SupplierDetails extends BasePage {
  constructor() {
    super()
  }

  // config text editor
  config: object = {
    advanced: {
      autogrow: true,
      removeformatPasted: true,
      btns: [
        ['viewHTML'],
        ['undo', 'redo'],
        ['formatting'],
        ['strong', 'em', 'del'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen'],
        ['tableAddRow'],
        ['tableAddColumn'],
        ['tableDeleteRow'],
        ['tableDeleteColumn'],
        ['table'],
        ['save']
      ]
    }
  };

  supplierData: any = {
    name: '',
    country: {
      items: [],
      selected: null,
      keyword: null,
      loading: false
    },
    website: '',
    acronym_name: '',
    invitation_code: '',
    min_order: 0,
    currency: {
      items: [],
      selected: 0,
      keyword: null,
      loading: false
    },
    pic: {
      name: '',
      phone: '',
      email: ''
    },
    refund_policy: '',
    warranty_policy: '',
    purchase_note: '',
    biography: '',
    logo: null,
    logo_preview: null,
    banner: null,
    banner_preview: null,
    users: []
  }

  // Table
  tableHeaders: object[] = [
    // {
    //   text: 'ID',
    //   align: 'left',
    //   sortable: false,
    //   value: 'id',
    // },
    {
      text: 'Name',
      align: 'left',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phone'
    },
    {
      text: 'Type',
      align: 'left',
      sortable: false,
      value: 'type'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'status',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    // sortBy: '-id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  searchCountryDebounce: Function
  searchCurrencyDebounce: Function
  searchClientDebounce: Function

  userDialog: boolean = false
  userData: any = {
    user: {
      items: [],
      selected: null,
      keyword: null,
      loading: false
    },
    type: {
      items: [
        {
          text: 'Customer',
          value: 'customer'
        },
        {
          text: 'Admin',
          value: 'admin'
        }
      ],
      selected: null
    }
  }

  indexDelUser: number = 0
  idDelUser: number = 0
  deleteDialog: boolean = false

  supplierID: string | number = null

  viaPlaceholder: any = 'https://via.placeholder.com/150?text=No-Image-Available';

  async mounted() {
    this.supplierID = this.$route.params.id
    this.getDetailSupplier()
    // this.getCountryOrigin()
    // this.getCurrency()
    this.searchCountryDebounce = debounce(this.getCountryOrigin, 500)
    this.searchCurrencyDebounce = debounce(this.getCurrency, 500)
    this.searchClientDebounce = debounce(this.getClient, 500)
  }

  @Watch('supplierData.country.keyword')
  async onChangedCountry() {
    await this.searchCountryDebounce()
  }

  @Watch('supplierData.currency.keyword')
  async onChangedCurrency() {
    await this.searchCurrencyDebounce()
  }

  @Watch('userData.user.keyword')
  async onChangedClient() {
    await this.searchClientDebounce()
  }

  async getDetailSupplier() {
    this.showLoading({ text: 'Loading...' })
    try {
      const opts: any = {
        params: {
          include: 'users,settings,country'
        }
      }

      const response = await SuppliersService.getOneSupplier(this.supplierID, opts)

      const data = response.data
      const included = response.included

      this.supplierData.name = data.attributes.name

      // push country item
      this.supplierData.country.items.push(
        {
          name: included.country[data.attributes.country_id].attributes.name,
          id: included.country[data.attributes.country_id].attributes.id
        }
      )
      this.supplierData.country.selected = data.attributes.country_id

      this.supplierData.website = data.attributes.website ? data.attributes.website : ''
      this.supplierData.acronym_name = data.attributes.acronim_name ? data.attributes.acronim_name : ''
      this.supplierData.invitation_code = data.attributes.invitation_code ? data.attributes.invitation_code : ''

      // get settings
      var settings = []
      if (!isEmpty(data.relationships.settings)) {
        forEach(data.relationships.settings, setting => {
          settings.push(included.settings[setting.id].attributes)
        })
        this.supplierData.min_order = find(settings, { key: 'min_order_value' }).value
        const currency = find(settings, { key: 'min_order_currency' }).value

        // push item currency 
        this.supplierData.currency.items.push({
          text: currency,
          value: currency
        })
        this.supplierData.currency.selected = currency
      } else {
        this.getCurrency()
      }

      this.supplierData.biography = data.attributes.biography ? data.attributes.biography : ''

      var pic_details = []

      if (typeof data.attributes.pic_details === 'string') {
        pic_details = JSON.parse(data.attributes.pic_details)
      } else {
        pic_details = data.attributes.pic_details
      }
      this.supplierData.pic.name = find(pic_details, { label: 'name' }).value
      this.supplierData.pic.phone = find(pic_details, { label: 'phone' }).value
      this.supplierData.pic.email = find(pic_details, { label: 'email' }).value

      this.supplierData.refund_policy = data.attributes.refund_policy ? data.attributes.refund_policy : ''
      this.supplierData.warranty_policy = data.attributes.warranty_policy ? data.attributes.warranty_policy : ''
      this.supplierData.purchase_note = data.attributes.purchase_note ? data.attributes.purchase_note : ''
      this.supplierData.logo_preview = data.attributes.logo
      this.supplierData.banner_preview = data.attributes.banner

      // get user list
      forEach(data.relationships.users, user => {
        const payload_user = {
          id: included.users[user.id].attributes.id,
          type: included.users[user.id].attributes.pivot.supplier_type
        }
        this.supplierData.users.push(payload_user)

        const item = {
          user: included.users[user.id].attributes,
          type: included.users[user.id].attributes.pivot.supplier_type
        }
        this.tableItems.push(item)
      })

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async getCountryOrigin() {
    this.supplierData.country.loading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.supplierData.country.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.supplierData.country.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await SuppliersService.getCountry(opts)

      const dataCountry = response.data.data

      for (const countryList of dataCountry) {
        const country: any = {
          name: countryList.attributes.name,
          id: countryList.attributes.id
        }
        this.supplierData.country.items.push(country)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.supplierData.country.loading = false
    }
  }

  async getCurrency() {
    this.supplierData.currency.loading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[currency][like]': ''
        }
      }
      if (!isEmpty(this.supplierData.currency.keyword)) {
        var textFilter = 'filter[currency][like]'
        opts.params[textFilter] = this.supplierData.currency.keyword
      } else {
        var textFilter = 'filter[currency][like]'
        delete opts.params[textFilter]
      }
      const response = await SuppliersService.getCountry(opts)

      const dataCountry = response.data.data

      for (const countryList of dataCountry) {
        const currency: any = {
          text: countryList.attributes.currency,
          value: countryList.attributes.currency
        }
        this.supplierData.currency.items.push(currency)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.supplierData.currency.loading = false
    }
  }

  changeLogo(e) {
    const file = e.target.files[0]
    this.supplierData.logo = file
  }

  changeBanner(e) {
    const file = e.target.files[0]
    this.supplierData.banner = file
  }

  async getClient() {
    this.userData.user.loading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (this.userData.user.keyword) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.userData.user.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await SuppliersService.getClient(opts)

      const dataClient = response.data.data

      for (const clientList of dataClient) {
        const client: any = {
          ...clientList.attributes,
          text: clientList.attributes.name + ' (' + clientList.attributes.email + ')',
          value: clientList.attributes.id
        }
        this.userData.user.items.push(client)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.userData.user.loading = false
    }
  }

  openCreateUser() {
    this.userData = {
      user: {
        items: [],
        selected: null,
        keyword: null,
        loading: false
      },
      type: {
        items: [
          {
            text: 'Customer',
            value: 'customer'
          },
          {
            text: 'Admin',
            value: 'admin'
          }
        ],
        selected: null
      }
    }
    this.userDialog = true
    this.getClient()
  }

  async addUser() {
    const validationResponse = await this.$validator.validateAll('UserDialogScope')
    if (validationResponse) {
      // push to table view
      const item = {
        user: this.userData.user.selected,
        type: this.userData.type.selected
      }
      this.tableItems.push(item)

      // push to payload
      const user = {
        id: this.userData.user.selected.id,
        type: this.userData.type.selected
      }
      this.supplierData.users.push(user)
      this.userDialog = false
    } else {
      this.showSnackbar({
        text: 'Please check all fields requirements',
        color: 'red',
        timeout: 2000
      })
    }
  }

  openConfirmDelete(index, id) {
    this.idDelUser = id
    this.indexDelUser = index
    this.deleteDialog = true
  }

  deleteUser() {
    this.tableItems.splice(this.indexDelUser, 1)
    const idPayloadUser = findIndex(this.supplierData.users, { id: this.idDelUser })
    this.supplierData.users.splice(idPayloadUser, 1)
    this.deleteDialog = false
  }

  async submitSupplier() {
    try {
      const validationResponse = await this.$validator.validateAll('supplierDialogScope')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        this.userDialog = false

        const pic_details = [
          {
            label: 'name',
            value: this.supplierData.pic.name ? this.supplierData.pic.name : ''
          },
          {
            label: 'phone',
            value: this.supplierData.pic.phone ? this.supplierData.pic.phone : ''
          },
          {
            label: 'email',
            value: this.supplierData.pic.email ? this.supplierData.pic.email : ''
          }
        ]

        const settings = [
          {
            key: 'min_order_value',
            value: this.supplierData.min_order
          },
          {
            key: 'min_order_currency',
            value: this.supplierData.currency.selected
          }
        ]

        let data = new FormData()
        data.append('name', this.supplierData.name)
        data.append('total_product', 0)
        data.append('website', this.supplierData.website)
        data.append('pic_details', JSON.stringify(pic_details))
        data.append('country_id', this.supplierData.country.selected)
        data.append('users', JSON.stringify(this.supplierData.users))
        data.append('settings', JSON.stringify(settings))
        // if (this.supplierData.acronym_name) {
        data.append('acronim_name', this.supplierData.acronym_name)
        // }
        // if (this.supplierData.invitation_code) {
        data.append('invitation_code', this.supplierData.invitation_code)
        // }
        // if (this.supplierData.biography) {
        data.append('biography', this.supplierData.biography)
        // }
        // if (this.supplierData.refund_policy) {
        data.append('refund_policy', this.supplierData.refund_policy)
        // }
        // if (this.supplierData.warranty_policy) {
        data.append('warranty_policy', this.supplierData.warranty_policy)
        // }
        // if (this.supplierData.purchase_note) {
        data.append('purchase_note', this.supplierData.purchase_note)
        // }
        if (this.supplierData.logo) {
          data.append('logo', this.supplierData.logo)
        }
        if (this.supplierData.banner) {
          data.append('banner', this.supplierData.banner)
        }

        let configfile = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
            Identifier: 'manage'
          }
        }

        SuppliersService.updateSupplier(this.supplierID, data, configfile).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.closeLoading()
          setTimeout(() => {
            this.$router.push('/suppliers')
          }, 2000)
        }).catch(error => {
          this.closeLoading()
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 1500
          })
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
    }
  }
}